<template>
    <modal ref="modalCargarCalendario" titulo="Cargar calendario" tamano="modal-lg" adicional="Cargar" @adicional="cargarCalendario">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-8 my-2">
                <p class="input-label-top">Calendario</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar calendario" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div v-for="(data,i) in 2" :key="i" class="col-8">
                 <div class="row my-3">
                    <div class="col-auto my-auto">
                        <p class="f-600 f-14 ucfirst"> Actividades ensamble </p>
                    </div>
                    <div class="col my-auto pl-0">
                        <hr class="my-0" />
                    </div>
                </div>
                <el-checkbox-group v-model="checkList" class="grupo-actividades">
                    <el-checkbox label="Opción A" class="d-flex"></el-checkbox>
                    <el-checkbox label="Opción B" class="d-flex"></el-checkbox>
                    <el-checkbox label="Opción C" class="d-flex"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            checkList: [],
            checked: false
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCargarCalendario.toggle()
        },
        cargarCalendario(){
            this.toggle()
        }
    }
}
</script>